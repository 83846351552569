.btn {
    @apply border border-theme-primary-100 bg-theme-primary-100 text-white font-bold py-3 px-5 rounded-full my-10
}

.btn:hover {
    @apply bg-theme-primary-200 border-theme-primary-200
}

.btn-open {
    @apply border border-theme-primary-100 text-theme-primary-100 py-3 px-8 rounded-full my-10
}

.btn-open:hover {
    @apply border-theme-primary-200  bg-theme-primary-200 text-white
}

.btn-white {
    @apply border-white text-white
}

.btn-open:hover {
    @apply bg-white text-theme-primary-100
}

.btn-ui {
    @apply border border-theme-offwhite-200 p-3 rounded mt-12 inline-flex flex-row items-center text-theme-secondary-100;
    svg + span {
        @apply ml-2
    }
}

.btn-ui:hover {
    @apply bg-theme-offwhite-100 text-theme-secondary-200
}

.btn-ui-no-margin {
    @apply border border-theme-offwhite-200 p-3 rounded  inline-flex flex-row items-center text-theme-secondary-100;
    svg + span {
        @apply ml-2
    }
}

.btn-ui-no-margin:hover {
    @apply bg-theme-offwhite-100 text-theme-secondary-200
}

.btn-ui-no-margin-no-colors {
    @apply border p-3 rounded  inline-flex flex-row items-center;
    svg + span {
        @apply ml-2
    }
}

.btn-ui-danger {
    @apply border border-theme-offwhite-200 p-3 rounded mt-12 inline-flex flex-row items-center text-theme-secondary-100;
    svg + span {
        @apply ml-2
    }
}

.btn-ui-danger:hover {
    @apply bg-theme-primary-100 text-white border-theme-primary-100
}

.btn-ui-danger-no-margin {
    @apply border border-theme-offwhite-200 p-3 mt-0 rounded inline-flex flex-row items-center text-theme-secondary-100;
    svg + span {
        @apply ml-2
    }
}

.btn-ui-danger-no-margin:hover {
    @apply bg-theme-primary-100 text-white border-theme-primary-100
}

.hot-delete-button {
    background: #E4003A !important;
    border-color: #E4003A !important;
    color: #fff;

    &:hover {
        background: #FF0E4B !important;
        color: #fff;
    }
}
