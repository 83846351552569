//  Used in register , for password for example, large tooltip
// Usage:
//  <div  class="tooltip">
//     <span class="tooltiptext">
        
//      </span>
//  </div>
.tooltip {
display: inline-block;    
}

.tooltip .tooltiptext {
    width : 320px;
    visibility: hidden;
    background-color: #FFF;
    border-radius:4px;
    border: 1px solid #aeaeae;
    position: absolute;
    z-index: 1;
    padding: 5px;
    margin-top : 5px; 
   opacity: 0;
    transition: opacity 1s;
}

//  Used in chat , for atendees, small tooltip on hover
// Usage:
// <div class="tooltip-hover">
//     <span class='tooltip-hover-text'> 
//     </span>
// </div>
.tooltip:hover .tooltiptext, .tooltip input:focus + .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip-hover .tooltip-hover-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip-hover:hover .tooltip-hover-text {
  visibility: visible;
}