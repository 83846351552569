@tailwind base;

:root {
  --transparent: transparent;
  --black: #22292f;
  --grey-darkest: #3d4852;
  --grey-darker: #606f7b;
  --grey-dark: #8795a1;
}

textarea { resize:both; } /* none|horizontal|vertical|both */
textarea.vert { resize:vertical; }
textarea.noResize { resize:none; }


body{
	font-family: 'Roboto', sans-serif;

	font-weight: 300;
	font-size: 14px;
	letter-spacing: 0.04em;
	// line-height: 1.8em;

	#background{
        position: fixed;
        opacity: 0.03;
        z-index: 0;
        left: 0;
        max-width: unset;
        max-height: unset;
        top: 180px;
        transform: rotate(-15deg);
        width: 100%;
    }
}

// TBV Heatmap charts
tspan {
  font-weight: normal;
}
tspan + tspan {
    fill: #8795a1;
    font-size: 13px;
}

.w-200px{
  width: 200px !important;
}

@tailwind components;
@tailwind utilities;

@import 'layouts/base.scss';
@import 'layouts/button.scss';

@import 'partials/sidebar.scss';
@import 'partials/breadcrumbs';
@import 'partials/chat.scss';
@import 'partials/checkbox.scss';
@import 'partials/autocomplete.scss';
@import "partials/basicLightbox.scss";
@import "partials/tooltip.scss";
@import "partials/modal.scss";

@import "partials/trix.scss";
@import "partials/trixBase.scss";
// @import 'partials/pong.scss';
@import "partials/residentialObjectStatus.scss";
@import "partials/accordion.scss";
@import "partials/formfields.scss";
// Material design settings and components
// $mdc-theme-secondary: #E4003A;
// $mdc-theme-on-secondary: #A21031;
// $mdc-theme-primary: #fff;
// $mdc-theme-on-primary: #fff;
// @import "@material/form-field/mdc-form-field";
// @import "@material/textfield/mdc-text-field";
// @import "@material/checkbox/mdc-checkbox";
// @import "@material/radio/mdc-radio";
// @import "@material/switch/mdc-switch";
// @import "@material/chips/mdc-chips";
