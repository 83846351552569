.trix ul {
  list-style-type: disc;
  padding-left: 2.5rem;
}
.trix ol {
  list-style-type: decimal;
  padding-left: 2.5rem;
}
.trix a {
  all:revert ;
}

.trix h1{
  display: block; font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em; margin-left: 0; margin-right: 0; font-weight: bold;
}

.trix blockquote{
  display: block; margin-top: 1em; margin-bottom: 1em; margin-left: 40px; margin-right: 40px;
}

.trix-button-group{
    background-color: #fff;
}
