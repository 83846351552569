[x-cloak] { display: none; }

#messages {
	scroll-behavior: smooth;
}

.toast{
	@apply absolute mt-40 mr-10 right-0 top-0 z-10 rounded-full shadow-xl px-8 py-3;
	border-width: 2px;
	animation: auto-hide 2s forwards;
	transform: translateX(200%);
}

.is-new-chat{	
	@apply bg-blue-100 text-black;
	&:hover{
		@apply bg-blue-300 text-black;
	}
	.subtitle{
		opacity: .75;
	}
}

.flag{
		@apply rounded-full w-10 h-10 flex items-center justify-center;
		transform: rotate(30deg);
	}


.is-active-chat{
	@apply bg-theme-primary-100 text-white;
	&:hover{
		@apply bg-theme-primary-150 text-white
	}
	.subtitle{
		opacity: .75;
	}
	.flag{
		@apply text-white;
	}
}

.is-flaged{
		transform: rotate(-38deg);
}

@keyframes auto-hide {
  0% {transform: translateX(200%);}
  5% {transform: translateX(0);}
  90%{transform: translateX(0);}
  100% {transform: translateX(200%);}
}


// #chatMsgTextArea {
// 	resize: none;
// 	box-sizing: content-box;
// 	border-top-left-radius: 22px;
// 	border-bottom-left-radius: 22px;
// 	padding: 8px 20px;
// 	width: 100%
// }