.basicLightbox {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.91);;
	opacity: .01;
	transition: opacity .4s ease;
	z-index: 1000;
	will-change: opacity
}

.basicLightbox--visible {
	opacity: 1
}

.basicLightbox__placeholder {
	max-width: 100%;
	-webkit-transform: scale(.9);
	transform: scale(.9);
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	z-index: 1;
	will-change: transform
}

.basicLightbox__placeholder>iframe:first-child:last-child,
.basicLightbox__placeholder>img:first-child:last-child,
.basicLightbox__placeholder>video:first-child:last-child {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	max-width: 95%;
	max-height: 95%
}

.basicLightbox__placeholder>iframe:first-child:last-child,
.basicLightbox__placeholder>video:first-child:last-child {
	pointer-events: auto
}

.basicLightbox__placeholder>img:first-child:last-child,
.basicLightbox__placeholder>video:first-child:last-child {
	width: auto;
	height: auto
}

.basicLightbox--iframe .basicLightbox__placeholder,
.basicLightbox--img .basicLightbox__placeholder,
.basicLightbox--video .basicLightbox__placeholder {
	width: 100%;
	height: 100%;
	pointer-events: none
}

.basicLightbox--visible .basicLightbox__placeholder {
	-webkit-transform: scale(1);
	transform: scale(1)
}

