#sidebar{
	font-size: 1em;
	line-height: 1em;
	overflow-y: scroll;
	

	li{
		@apply border-b border-theme-primary-150 bg-theme-primary-100 relative;
		&:hover {
			@apply bg-theme-primary-200;
		}

		&.active{
			@apply bg-theme-primary-200
		}

		ul.submenu li.active{
			@apply bg-theme-secondary-300	
		}

		a{
			@apply flex flex-row items-center py-3 px-8;
		}
	}

	
	// li:last-child{
	// 	border:none;
	// }

	// > ul > li:last-child :after{
	// 	@apply bg-theme-primary-100;
	// 	display: block;
	// 	content: '';
	// 	height:100vh;
	// 	width: 100%;
	// 	z-index: -1;
	// 	position: absolute;
	// 	left: 0;
	// }
	.submenu{
		@apply bg-white shadow-xl;
		position: relative;
		top:0;
		z-index: 1;
		transition: all ease-in 0.1s;
		li{
			@apply bg-theme-secondary-200 border-b border-theme-secondary-300 flex flex-row items-center py-2 px-2;
			&:hover{
				@apply bg-theme-secondary-300;
			}
		}
	}

	.carret:after{
		margin-left: 10px;
		margin-bottom: -2px;
		margin-top: 5px;
		display: inline-block;
		position: absolute;
		right: 20px;
		content: '';
		width: 0; 
		height: 0; 
		opacity: 0.2;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 7px solid #000;
		transition: all ease-in-out .2s;
	}

	.active.carret:after{
		transform: rotate(90deg);
		border-left: 7px solid #fff;
		opacity: 0.5;
	}

}