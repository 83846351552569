input, select{
  border-radius: theme('borderRadius.sm');
  background-color: theme('colors.theme-offwhite-50');
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.1s ease-in-out;
  &:focus {
    box-shadow: 0 0 5px #4F5169;
    border: 1px solid #656C97;
    background-color: theme('colors.white');
  }
}

.login-page {
    input {
        &:focus {
            background-color: theme('colors.theme-primary-300')!important;
            color: theme('colors.white')!important;
        }
    }
}

.form-select{
  color-adjust: exact;
  appearance: none;
  background-color: #fff;
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  border-radius: .5rem;
  font-size: 1rem;
  line-height: 1.5;
  padding: .5rem 2.5rem .5rem .75rem;
  -webkit-print-color-adjust: exact;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23e2e8f0' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.293 12.95.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z'/%3E%3C/svg%3E");
}


checkbox {
  width: theme('spacing.5');
  height: theme('spacing.5');
  transition: all .2s;
  &:checked {
    background-size: 70%;
    transition: all .1s;
  }
}
