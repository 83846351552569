

.shepherd-button{background:#3288e6;border:0;border-radius:3px;color:hsla(0,0%,100%,.75);cursor:pointer;margin-right:.5rem;padding:3px 6px;transition:all .5s ease}.shepherd-button:not(:disabled):hover{background:#196fcc;color:hsla(0,0%,100%,.75)}.shepherd-button.shepherd-button-secondary{background:#f1f2f3;color:rgba(0,0,0,.75)}.shepherd-button.shepherd-button-secondary:not(:disabled):hover{background:#d6d9db;color:rgba(0,0,0,.75)}.shepherd-button:disabled{cursor:not-allowed}
.shepherd-footer{border-bottom-left-radius:5px;border-bottom-right-radius:5px;display:flex;justify-content:flex-end;padding:0 .75rem .75rem}.shepherd-footer .shepherd-button:last-child{margin-right:0}
.shepherd-cancel-icon{background:transparent;border:none;color:hsla(0,0%,50.2%,.75);font-size:2em;cursor:pointer;font-weight:400;margin:0;padding:0;transition:color .5s ease}.shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}.shepherd-has-title .shepherd-content .shepherd-cancel-icon{color:hsla(0,0%,50.2%,.75)}.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-title{color:rgba(0,0,0,.75);display:flex;font-size:1rem;font-weight:400;flex:1 0 auto;margin:0;padding:0}
.shepherd-text{color:rgba(0,0,0,.75);font-size:1rem;line-height:1.3em;padding:.75em}.shepherd-text p{margin-top:0}.shepherd-text p:last-child{margin-bottom:0}
.shepherd-content{border-radius:5px;outline:none;padding:15px 20px}
.shepherd-element{background:#bbe3f2;border-radius:5px;box-shadow:0 1px 4px rgba(0,0,0,.2);max-width:400px;opacity:0;outline:none;transition:opacity .3s;z-index:9999}.shepherd-enabled.shepherd-element{opacity:1}.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{box-sizing:border-box}.shepherd-element .shepherd-arrow{border:16px solid transparent;content:"";display:block;height:16px;pointer-events:none;position:absolute;width:16px;z-index:10000}.shepherd-element.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-arrow{bottom:0;border-top-color:#bbe3f2;left:50%;transform:translate(-50%,100%)}.shepherd-element.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-arrow{border-bottom-color:#bbe3f2;left:50%;top:0;transform:translate(-50%,-100%)}.shepherd-element.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-arrow{border-bottom-color:#e6e6e6}.shepherd-element.shepherd-element-attached-middle.shepherd-element-attached-left .shepherd-arrow{border-right-color:#bbe3f2;left:0;top:50%;transform:translate(-100%,-50%)}.shepherd-element.shepherd-element-attached-middle.shepherd-element-attached-right .shepherd-arrow{border-left-color:#bbe3f2;right:0;top:50%;transform:translate(100%,-50%)}.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,.shepherd-target-click-disabled.shepherd-enabled.shepherd-target *{pointer-events:none}
.shepherd-modal-overlay-container{-ms-filter:progid:dximagetransform.microsoft.gradient.alpha(Opacity=25);filter:alpha(opacity=25);fill-rule:evenodd;height:0;left:0;opacity:0;overflow:hidden;pointer-events:none;position:fixed;top:0;transition:all .3s ease-out,height 0ms .3s,opacity .2s 0ms;width:100vw;z-index:9997}.shepherd-modal-overlay-container.shepherd-modal-is-visible{height:100vh;opacity:.25;transition:all .3s ease-out,height 0s 0s,opacity .3s 0s}.shepherd-modal-overlay-container.shepherd-modal-is-visible path{pointer-events:all}



slot{
	display: none;
}
body:after{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content:center;
	content: 'Offline';

	background: #000;
	position: fixed;
	top: 0;
	right:0;
	bottom:0;
	left:0;
	z-index:-1;
	opacity: 0;
	transition: all 1s;

}

body.darken:after{
	z-index:1000;
	opacity: 0.95;
	transition: all 2s;
}

body .offline-msg{
	z-index: -1;
	opacity: 0;
	position: absolute;
	top: 15%;
	left: 0;
	right: 0;
	text-align: center;
	margin: 0 auto;
	padding: 50px;
	width: 400px;
	pointer-events: none;
}


body.darken	.offline-msg{
	z-index: 2000;
	opacity: 1;
}

button, input, optgroup, select, textarea {
	font-weight: 300;
	font-size: 1em;
}
label{
	font-weight: 500;
}

input {
	filter: none;
}

input[value="[--- INVULLEN ---]"] {
	color: #E4003A;
	background-color: #fbe2e2 !important;
	border: 1px solid #E4003A;
}

table.zebra tr:nth-child(even){
	@apply bg-theme-offwhite-50
}

table.zebra tr.clickable:hover{
	@apply bg-theme-offwhite-75
}

table .ellipsis {
	text-align: left;
	position: relative;
}
table .ellipsis:before {
	content: ' ';
	visibility: hidden;
}
table .ellipsis span {
	position: absolute;
	left: 0;
	right: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}



#topbar{
	min-height: 58px;
	font-size: 1em;
}


.page-header{
	@apply pb-12 flex flex-row items-center w-full justify-between flex flex-row items-center

}
.page-header .svg{
	@apply bg-theme-offwhite-200 border-4 border-theme-secondary-200 mr-4 text-theme-secondary-200  p-4 rounded-full
}
.page-header h1{
	@apply text-5xl font-extrabold text-theme-secondary-200;
	line-height: 1;
	.subtitle{
		margin-top: 0;
		margin-left: 2px;
	}
}

.page-header .title{
	@apply flex flex-row items-center
}
.wizard-header{
	@apply flex  mx-auto  flex-row w-1/2 items-center justify-between  bg-theme-secondary-200

}
.wizard-header .svg{
	@apply bg-theme-offwhite-100 border-4 border-theme-secondary-300 mr-4 text-theme-secondary-300  p-4 rounded-full
}
.wizard-header h1{
	@apply text-5xl font-extrabold text-theme-secondary-200;
	span{
		@apply text-white
	}
	.subtitle{
		@apply text-lg mt-3 ml-0 font-normal text-theme-gray-300
	}
}
.wizard-header .title{
	@apply flex flex-row items-center
}
.wizard-box{
	@apply flex flex-col  mx-auto
}
.wizard-box-form {
	@apply pt-24 pb-20 px-10 shadow-lg bg-white rounded w-full mb-16 h-full w-full
}
.wizard-box-label{
	margin-bottom: -56px;
	// z-index: 100;
	width: 350px;
	border-bottom-right-radius: 25px;
	@apply inline-flex text-lg  ml-0 p-4 z-10   pl-8  bg-theme-offwhite-300 text-theme-secondary-200 w-1/3;
}
.wizard-box-label svg{
	@apply mr-2;
}
.wizard-box-button{
	@apply flex flex-row my-3 float-right justify-between items-center
}



.header-buttons{
	@apply flex flex-row
}
.header-buttons .header-btn:not(:last-child){
	@apply mr-3
}
.header-btn{
	@apply text-white flex items-center flex-row border py-4 px-5 bg-theme-secondary-200
}
.header-btn:hover{
	@apply bg-theme-secondary-300
}
.content-box{
	@apply pt-24 pb-20 px-10 shadow-lg bg-white rounded w-full mb-16 h-full
}
.content-box-no-padding{
	@apply shadow-lg bg-white  rounded w-full mb-16 h-full
}
.content-box-no-height{
	@apply pt-24 pb-20 px-10 shadow-lg bg-white rounded w-full mb-16 h-auto
}
.content-box-label{
	margin-bottom: -59px;
	width: 350px;
	border-bottom-right-radius: 25px;
	@apply inline-flex text-lg text-white ml-0 p-4 z-10 bg-theme-secondary-200;
}
.content-box-label svg{
	@apply mr-2;
}

.content-box-tab{
	@apply pt-24 pb-20 px-10 bg-white rounded  w-full mb-16 h-full
}

.space-items > div:not(:last-child){
	@apply mr-10
}
.alert-toast.show {
	height: auto;
	max-height: 0px;
	animation: slide-in-right .15s ease-in-out both;
}
.alert-toast:not(.show) {
	animation: slide-out-right .1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.transition{
	transition: all .1s;
}

.blur{
	will-change: opacity;
	transition: opacity .4s;
	opacity: 0.4;
}
.blur-table td{
	will-change: opacity;
	transition: opacity .4s;
	opacity: 0.4;
}

.show-content-fx{
	animation: slide-in-bottom .3s cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s both;
}

.show-login-fx{
	animation: slide-in-right .3s cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s both;
}
.hide-content-fx{
	animation: hideCard .3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.flipable{
	transform-style: preserve-3d;
	animation: rotation-rev ease-in-out .2s both ;
}
.flip-v{
	transform-style: preserve-3d;
	animation: rotation ease-in-out .2s both;
}
.slide-out-right-no-fade{
	animation: slide-out-right-no-fade .3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-right{
	animation: slide-in-right .3s ease-out .5s both;
}
.loginform{
	animation-delay: 1.1s;
}
@keyframes slide-out-right-no-fade{0%{ transform:translateX(0px);}100%{ transform:translateX(100%);}}

.fade-in{
	animation: fade-in .3s ease-out .5s both;
}

.flicker {
	animation: rotation360 2s infinite ease-in-out;
}

.crm-selected a{
	@apply bg-theme-primary-100 text-white
}

.map-marker-no-route{
	@apply bg-theme-primary-100 rounded-full w-5 h-5
}

.map-marker{
	@apply bg-theme-primary-100 cursor-pointer rounded-full w-5 h-5
}
.map-marker:hover{
	@apply bg-theme-primary-200 w-6 h-6;
}

.map-marker-new{
	@apply bg-theme-secondary-100 cursor-pointer rounded-full w-5 h-5

}


.map{
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.error-message{
	position: absolute; margin-top: -33px; color: #f56565; right: 15px;
}




.mapboxgl-popup-content{
	background: #424242;
	// @apply bg-theme-offwhite-200
}

.mapbox-button{
	@apply text-white  bg-theme-primary-100  text-lg font-semibold py-2 px-4  cursor-pointer
}

.mapboxgl-popup-close-button{
	display:none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
	border-top-color:#424242;
	border-width: 28px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
	border-bottom-color: #424242;
	border-width: 28px;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
	border-right-color: #424242;
	border-width: 28px;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
	border-left-color: #424242;
	border-width: 28px;
}
.tab-child{
	flex: 1 0 21%
}

.loading-icon {
	animation: rotation360 0.8s infinite linear;
}
.changed-item{
	will-change: font-weight;
	animation: changed 1s ease-in-out;
}

// input[type=text] {
// 	background-color: white;
// }






.remove-file {
	cursor: pointer;
}

.move-file {
	@apply bg-theme-offwhite-100 text-black cursor-pointer

}

.download-file {
	@apply bg-theme-offwhite-100 text-black cursor-pointer
}


.button-file-group{
	width: 100%;
	padding: 0.2rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	a {
		padding: 1em;
		margin: .3em;
		flex-grow: 1;
		svg{
			display: block;
			margin: auto;
		}
	}
}

.media-grid-item{
	@apply w-full h-auto rounded;
}



.tag-list-trigger > button:after {
	content: "\25BC";
	font-size: 10px;
	padding-left: 6px;
	position: relative;
	top: -1px;
}

.tag-list  {
	padding-left: 6px;
	position: absolute;
	z-index: 20;
}

.copied{
	animation: changed 0.5s ease-in-out;
	position:relative;
	&:after{
		content: "Gekopieerd!";
		position: absolute;
		display: inline-block;
		margin-left: 10px;
		color: #e4003a;
	}
}


.toggle{
	cursor: pointer;
	will-change: transform;
	transition: all .1s;
	&:not(.active) .toggle-inner{
		transform: translateX(-100%);
		transition: all .1s;
	}
	&.active .toggle-inner{
		transform: translateX(0%);
		transition: all .1s;
	}
}

input:focus + .validation-error-message  {
	display: none;
}

.mapbox-wizard{
	height: 30rem;
}

.survey-icon-container{
	align-items: center;
	justify-content: center;
	display: flex;
	flex-flow: row wrap;
}
.survey-dragging{
	@apply flex justify-center items-center py-3 border-dashed border-4 px-3 w-full bg-gray-100 h-20;
	img{
		display: none;
	}
}
#questions-list:empty{
	@apply flex justify-center items-center py-3 border-dashed border-4 px-3 w-full bg-gray-100 h-20;
	&:after{
		display: block;
		content: 'Nog geen vragen in deze enquête. Sleep een blok hierheen om te starten.';
		text-align: center;
	}
}
@keyframes fade-in{0%{opacity:0}100%{;opacity:.85}}

@keyframes slide-in-left{0%{ transform:translateX(-100%);}100%{ transform:translateX(0);}}
@keyframes slide-in-top{0%{transform:translateY(-300px);opacity:0}100%{transform:translateY(0);opacity:1}}
@keyframes slide-out-top{0%{transform:translateY(0);opacity:1}100%{transform:translateY(-300px);opacity:0}}
@keyframes slide-in-bottom{0%{transform:translateY(30px);opacity:0}100%{transform:translateY(0);opacity:1}}
@keyframes slide-in-bottom-300{0%{transform:translateY(300px);opacity:0}100%{transform:translateY(0);opacity:1}}
@keyframes slide-out-bottom{0%{transform:translateY(0);opacity:1}100%{transform:translateY(300px);opacity:0}}
@keyframes slide-in-right{0%{ transform:translateX(100%);}70%{ transform:translateX(10%);}90%{ transform:translateX(2%);}100%{ transform:translateX(0);}}
@keyframes slide-out-right{0%{ transform:translateX(0px);opacity:1}100%{max-height: 100px; transform:translateX(300px);opacity:0; height: 0px;}}
@keyframes fade-out-right{0%{transform:translateX(0);opacity:1}100%{-webkit-transform:translateX(50px);transform:translateX(50px);opacity:0}}
@keyframes flash{0%{opacity:0}10%{opacity:1}20%{opacity:0}30%{opacity:1}40%{opacity:0}50%{opacity:1}60%{opacity:0}70%{opacity:1}80%{opacity:0}90%{opacity:1}}
@keyframes rotation{
	100%{
		transform: rotateX( -0deg);
		// transform: rotate(-0deg);
	}
	100%{
		transform: rotateX(-180deg);
		// transform: rotate(-180deg)
	}
}
@keyframes rotation-rev{
	0% {
		transform: rotateX(180deg);
	}
	100%{
		transform: rotateX(0deg);
	}
}

@keyframes rotation360{0%{transform: rotate(-0deg);}100%{transform: rotate(360deg)}}

@keyframes flicker {  0%{ opacity: 0; } 50%{ opacity: 1; } 100%{ opacity: 0; }}

@keyframes changed {
	0%{    background-color: #E5E5E8;}
	75%{    background-color: #E5E5E8;}
	100%{   background-color: transparent;  }
}


@keyframes hideCard {
	0% {
		opacity: 1;
		transform: translateY( 0px );
	}
	30% {
		opacity: 1;
		transform: translateY( -20px );
	}
	100% {
		opacity: 0;
		transform: translateY( 0px );
	}
}

.toggle-checkbox{
	width: 25px !important;
	border-width: 3px !important;
	margin-top: -1px;
	border-color: theme('colors.gray.400');
}

.toggle-checkbox:checked {
	right: 0;
	border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}
.circle-timeline{
	left: 39px;
	background-color: #fff;
	width: 50px;
	height: 50px;
	content: "";
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}


.table-header-rotated {
	border-collapse: collapse;

	th.rotate {
		height: 140px;
		white-space: nowrap;
		> div {
			transform: translate(-165px, -10px) rotate(45deg);
			text-align: right;
			width: 200px;
		}
		> div > a {
			border-bottom: 1px solid #ccc;
			padding: 0px 10px;
			display: inline-block;
		}
	}
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 4s infinite;
    content: '';
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
