.tab-content {
    max-height: 100vh;
    margin-bottom: 1rem;
}
.tab input:checked ~ .tab-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;

}
