/*
ANIMATED CHECKBOX
Usage example: 
<label class="checkbox-label">
  <input type="checkbox"> Uitgebreid zoeken
  <div class="checkbox-check"></div>
</label>
*/


label.checkbox-label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    line-height: 145%;
    cursor: pointer;
    user-select: none; 
    white-space: nowrap;

    input[type="checkbox"] {
        position: relative;
        margin: 0 15px 0 0;
        cursor: pointer;
        appearance: button-bevel;
        opacity: 0;
        + div.checkbox-check {
            transition: all .2s ease-in-out;
            content: "";
            position: absolute;
            left: 0;
            z-index: 1;
            width: 20px;
            height: 20px;
            @apply border-theme-offwhite-300;
            border-width: 2px;
            border-style: solid;
            background: transparent;
        }

        &:hover {
            + div.checkbox-check {
                 @apply border-theme-secondary-200;
            }
        }

        &:checked {
            + div.checkbox-check {
                transform: rotate(-230deg) translateX(-4px) translateY(1px);
                height: 10px;
                @apply border-theme-secondary-200;
                border-bottom-style: none;
                border-left-style: none;
                border-right-width: 2px;
                border-top-width: 2px;
                background: rgba( 255, 255, 255, 0);
                transition: all .3s ease-in-out;
            }
        }
    }
}

label.checkbox-label-light {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 5px;
    line-height: 145%;
    cursor: pointer;
    user-select: none; 
    white-space: nowrap;

    input[type="checkbox"] {
        position: relative;
        margin: 0 15px 0 0;
        cursor: pointer;
        appearance: button-bevel;
        opacity: 0;
        + div.checkbox-check {
            transition: all .2s ease-in-out;
            content: "";
            position: absolute;
            left: 0;
            z-index: 1;
            width: 20px;
            height: 20px;
            @apply border-theme-offwhite-300;
            border-width: 2px;
            border-style: solid;
            background: transparent;
        }

        &:hover {
            + div.checkbox-check {
                 @apply border-theme-offwhite-300;
            }
        }

        &:checked {
            + div.checkbox-check {
                transform: rotate(-230deg) translateX(-4px) translateY(1px);
                height: 10px;
                @apply border-theme-offwhite-300;
                border-bottom-style: none;
                border-left-style: none;
                border-right-width: 2px;
                border-top-width: 2px;
                background: rgba( 255, 255, 255, 0);
                transition: all .3s ease-in-out;
            }
        }
    }
}

// /*
// ANIMATED CHECKBOX
// Usage example: 
// <label class="checkbox-label">
//   <input type="checkbox"> Uitgebreid zoeken
// </label>
// */

// $checkColor: $primary;
// label.checkbox-label {
//     position: relative;
//     line-height: 145%;
//     cursor: pointer;
//     font-weight: 400;
//     user-select: none; 

//     input[type="checkbox"] {
//         position: relative;
//         margin: 0 30px 0 0;
//         cursor: pointer;
//         appearance: button-bevel;
//         &:before {
//             transition: all .1s ease-in-out;
//             content: "";
//             position: absolute;
//             left: 0;
//             z-index: 1;
//             width: 20px;
//             height: 20px;
//             border: 2px solid #d1cfd0;
//             background: rgba( 255, 255, 255, 0.7);
//             margin: -17px 0px;
//         }

//         &:hover {
//             &:before {
//                 border-color: $checkColor;
//             }
//         }

//         &:checked {
//             &:before {
//                 transform: rotate(-45deg);
//                 height: 10px;
//                 border-color: $checkColor;
//                 border-top-style: none;
//                 border-right-style: none;
//                 border-left-width: 2px;
//                 border-bottom-width: 2px;
//                 margin-top: -15px 0px;
//                 background: rgba( 255, 255, 255, 0);
//             }
//         }
//     }
// }