
$crumbs-active-color:#4F5169;
$crumbs-back:#858696;
$text-color:#fff;

#crumbs {
	text-align: center;
	position: absolute;
	left:0;

	ul {
		list-style: none;
		display: flex;
		flex-direction: row;
		li {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 1em;

			a{
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 58px;
				background: $crumbs-back;
				position: relative;
				margin: 0 2px 0 0; 
				text-decoration: none;
				color: $text-color;

				&:after {
					content: "";  
					border-top: 29px solid transparent;
					border-bottom: 29px solid transparent;
					border-left: 20px solid $crumbs-back;
					position: absolute; 
					right: -20px;
					top: 0;
					z-index: 1;
				}
				&:before {
					content: "";  
					border-top: 29px solid transparent;
					border-bottom: 29px solid transparent;
					border-left: 20px solid #fff;
					position: absolute; 
					left: 0; 
					top: 0;
				}
			}

			&.first{
				a{
					padding: 0px 12px 0 85px;
					background-color: #4F5169;
					&:before {
						display: none; 
					}
					&:after {
						border-left: 20px solid #4F5169;
					}
				}
			}

			&.middle a{
				padding: 0px 3px 0 35px;
			}
			&.last span{
				padding: 0px 3px 0 29px;
				color: $crumbs-back;
			}

		}
	}
}


#crumbs ul li a:hover {
	background: $crumbs-active-color;
	color:#fff;
}
#crumbs ul li a:hover:after {
	border-left-color: $crumbs-active-color;
	color:#fff;
}

